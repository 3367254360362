:root {
  --primary-color: #10530e;
  --secondary-color: #117a30;
  --background-color: #f8f9fa;
  --text-color: #333;
  --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  color: var(--text-color);
  background-image: linear-gradient(to right top, #10530e, #117a30, #199e5b, #33c58b, #5aeac3, #5aebd3, #5aebd3, #5aeac3, #33c58b, #199e5b, #117a30, #10530e);
  background-size: cover;
  
}

.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Fill the viewport height */
  padding: 2rem;
  background-color: var(--background-color);
  transition: background-color 0.3s ease;
}

.contact-form {
  width: 100%;
  max-width: 800px; /* Increased width for a retro look */
  padding: 4rem; /* Increased padding for a retro look */
  background: #f8f9fa; /* Light gray background for a retro look */
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.contact-form input,
.contact-form textarea,
.contact-form button {
  width: 100%;
  padding: 15px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Include padding in the element's total width and height */
  background-color: #f8f9fa; /* Light gray background for input fields */
  color: #333; /* Dark gray text color for input fields */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contact-form button {
  background-color: #10530e; /* Dark green button color for a retro look */
  color: white;
  font-weight: bold;
  border: none;
}

.contact-form button:hover {
  background-color: #117a30; /* Slightly lighter green button color on hover */
  transform: translateY(-3px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
}

.contact-form textarea {
  height: 150px; /* Increased height for a retro look */
}

.contact-form label {
  color: #333; /* Dark gray label color for a retro look */
  font-weight: bold;
}

.contact-form label span {
  color: #10530e; /* Dark green span color for a retro look */
}

.contact-form input[type="checkbox"] {
  margin-right: 0.5rem;
}

.contact-form input[type="checkbox"] + label {
  color: #333; /* Dark gray label color for a retro look */
}

.contact-form input[type="checkbox"] + label span {
  color: #10530e; /* Dark green span color for a retro look */
}

.contact-form input[type="radio"] {
  margin-right: 0.5rem;
}

.contact-form input[type="radio"] + label {
  color: #333; /* Dark gray label color for a retro look */
}

.contact-form input[type="radio"] + label span {
  color: #10530e; /* Dark green span color for a retro look */
}
.contact-form input,
.contact-form textarea,
.contact-form button {
    width: 100%;
    padding: 15px;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Include padding in the element's total width and height */
}

.contact-form button {
    background-color: var(--primary-color);
    color: white;
    font-weight: bold;
    border: none;
}

.contact-form button:hover {
    background-color: darken(var(--primary-color), 10%);
    transform: translateY(-3px);
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}



button {
  font-family: var(--font-family);
}

nav {
  background-color: var(--primary-color);
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
}

nav button {
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

nav button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
header, section {
  padding: 2rem;
  margin: 1rem auto;
  max-width: 960px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
}

header {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
}

header h1 {
  margin: 0.5rem 0;
  
}

header p {
  margin: 0.25rem 0;
}
section h1{
  color: #490000;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 0.5rem;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input, textarea {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid var(--secondary-color);
}

button[type="submit"] {
  padding: 0.5rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: darken(var(--primary-color), 10%);
}
@media (max-width: 768px) {
  header, section {
      margin: 1rem;
      padding: 1rem;
  }

  nav {
      flex-direction: column;
  }

  nav button {
      margin: 5px 0;
  }
}
ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #f0f0f0;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 5px;
}
/* Existing styles preserved for context */

/* Updated grid layout for repositories */
ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create three columns */
  gap: 1rem; /* Adjust the gap between grid items */
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #f0f0f0;
  margin: 0; /* Adjusted for the grid layout */
  padding: 0.5rem;
  border-radius: 5px;
}

/* You may adjust these styles as needed to fit the new grid layout */
.repo-container {
  text-align: center;
  background-position: right;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

@keyframes metalicStripes {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

.repo-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
  transform: translateX(-100%);
}

@keyframes eagleFly {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.repo-container a {
  color: #410f0f; /* White text color for better contrast */
}

.repo-container p {
  color: #e0e0e0; /* Lighter text for the description for better contrast */
}

.repo-container:hover::before {
  animation: metalicStripes 2s infinite linear;
}
.occ{
  background-color: #84d881;
  
  margin: 0; /* Adjusted for the grid layout */
  padding: 0.5rem;
  border-radius: 5px;
}
a {
  color: var(--primary-color);
  font-weight: bold;
  margin: .2rem;
}
h3{
  color: #022815;
  font-weight: bold;
  font-style: italic;
}
.education-entry {
  margin-bottom: .4rem;
}
p{
  margin: .2rem;
  color: #c8c8c8;
  font-weight: bold;
}
.education-logo {
  height: 90; /* or the size you prefer */
  margin-right: 1rem;
  vertical-align: middle; /* to align with the text */
}
.site-header {
  background-color: #fff; /* Adjust if you have a specific header background color */
  padding: 1rem;
  text-align: center; /* Center the logo */
  border-bottom: 1px solid #eaeaea; /* Optional border for stylistic separation */
}

.unt-logo {
  height: 175px; /* Adjust the size as necessary */
}
.unt-logo2{
  height: 100px;
}
.app-container {
  transition: background-color 0.3s ease;
  /* rest of your styles */
}
@keyframes backgroundColorAnimation {
  0% {
      background-color: #004d00; /* dark green */
  }
  50% {
      background-color: #80e080; /* lighter green */
  }
  100% {
      background-color: #004d00; /* dark green */
  }
}

.app-container {
  animation: backgroundColorAnimation 10s infinite;
  /* rest of your styles */
}
body {
  background-color: #247a56;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  /* rest of your styles */
}
.repo-container {
  text-align: center;
  background-color: rgb(40, 137, 150);
  padding: 2rem;
}

@media (max-width: 768px) {
  ul {
    grid-template-columns: repeat(2, 1fr); /* Show only 2 columns on smaller screens */
  }
}

@media (max-width: 480px) {
  ul {
    grid-template-columns: 1fr; /* Show single column on very small screens */
  }
}

.repo-container a {
  color: #fff; /* White text color for better contrast */
}

.repo-container p {
  color: #e0e0e0; /* Lighter text for the description for better contrast */
}
.git h2 {
  display: block; /* Changed to block for full width */
  margin: 1rem auto; /* Added vertical and horizontal auto margin */
  width: fit-content; /* Makes the box fit the content width */
  font-style: bold;
  text-align: center;
  background-color: #e7ede4;
  padding: .4rem 1rem; /* Added more horizontal padding */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(124, 84, 84, 0.1);
}

.header a{
  color: #e7ede4;
  font-size: 20px;
  font-style: italic;
  
}
i{
  font-size: 20px;
  color-scheme: #e7ede4;
}
h6 {
  color: hwb(115 5% 91% / 0.8);
  box-shadow: 0 2px 4px hsla(103, 94%, 20%, 0.761);
  font-size: 40px;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

@keyframes backgroundPulse {
  0%, 100% {
      background-color: #e8f5e9; /* lighter green */
  }
  50% {
      background-color: #81c784; /* slightly darker green */
  }
}

.resume-container {
  /* ...existing styles... */
  animation: backgroundPulse 5s infinite alternate;
}
.research-papers-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
}
h5{
  color: white;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 
}

.research-paper {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.research-paper:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.research-paper h3 {
  color: var(--primary-color);
}

.research-paper p {
  color: var(--secondary-color);
}
.interactive-component {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px; /* Optional for rounded corners */
}

.interactive-component:hover {
  transform: scale(1.05); /* Makes the component slightly larger */
  box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Enhances the shadow for a highlighted effect */
}
.interactive-text {
  transition: background-color 0.3s ease;
  padding: .2px 0; /* Ensures the background highlight doesn't look too cramped */
}

.interactive-text:hover {
  border-radius: 8px; /* Optional for rounded corners */

  background-color: rgba(17, 38, 144, 0.2); /* Light yellow background on hover */
}
